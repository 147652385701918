@use '@/styles/utils/mixins.scss' as *;
.bannerWrapper {
  background: $kiaMidnightBlack;
  position: relative;
  min-height: calc(100vh - 80px);
  display: flex;
  @include mob-tab() {
    flex-direction: column-reverse;
    min-height: auto;
  }
  .headingElement {
    position: relative;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;
      &.titleXl {
        font-size: 60px;
        line-height: 1;
        @include tab() {
          font-size: 48px;
          line-height: 1;
        }
        @include mob() {
          font-size: 36px;
          line-height: 1;
        }
      }
      &.titleLg {
        font-size: 48px;
        line-height: 1;
        @include tab() {
          font-size: 36px;
          line-height: 1;
        }
      }
      &.titleMd {
        font-size: 20px;
        line-height: 1;
      }
      &.titleXs {
        font-size: 16px;
        line-height: 1;
      }
    }
    @include mob-tab() {
      text-align: center;
      max-width: 100%;
    }
  }
  .bannerText {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.2;
    @include mob-tab() {
      font-size: 14px;
      font-weight: 400;
    }
  }
  .bannerImage {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    @include mob-tab() {
      position: static;
      min-height: 300px;
      height: auto;
    }
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      @include mob-tab() {
        position: static;
        min-height: 300px;
        height: auto;
      }
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      background: linear-gradient(
        90deg,
        rgba(5, 20, 31, 1) 0%,
        rgba(5, 20, 31, 0) 80%
      );
      @include mob-tab() {
        display: none;
      }
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      opacity: 1;
      background: linear-gradient(
        0deg,
        rgba(5, 20, 31, 1) 0%,
        rgba(5, 20, 31, 0) 45%
      );
      @include mob-tab() {
        display: none;
      }
    }
  }
  .bannerContentWrapper {
    z-index: 2;
    color: $kiaPolarWhite;
    align-items: center;
    display: flex;
    .bannerContent {
      max-width: 740px;
      text-align: left;
      width: 100%;
      @include mob-tab() {
        padding: 48px 24px;
        text-align: center;
        max-width: 100%;
      }
    }
  }
}
